import Link from "next/link";
import { PropsWithChildren } from "react";

export interface ILinkButtonProps {
	href: string;
	tooltip: string;
	tabIndex?: number;
	className?: string;
	size?: Size;
}

export type Size = "small" | "medium" | "large";

const LinkButton: React.FC<PropsWithChildren<ILinkButtonProps>> = ({
	href,
	tooltip,
	tabIndex,
	children,
	className,
	size = "medium",
}) => {
	return (
		<Link
			tabIndex={tabIndex}
			href={href}
			title={tooltip}
			className={`${
				className ? className : ""
			} transition ease-in-out duration-300 inline-block font-title ${
				size === "large"
					? "text-base 2xs:text-lg sm:text-xl 2xs:py-4 sm:py-5 2xs:px-8 sm:px-10"
					: size === "medium"
					? "text-sm 2xs:text-base sm:text-lg"
					: "text-xs 2xs:text-sm sm:text-base"
			} font-semibold text-white bg-epic-light-red focus-within:bg-epic-brown-purple hover:bg-epic-brown-purple px-4 py-2 rounded-full`}
		>
			{children}
		</Link>
	);
};

export default LinkButton;
