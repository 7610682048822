import Image from "next/image";
import { PropsWithChildren } from "react";
import { Heading1 } from "~/components/layout/headings";
import LinkButton from "~/components/layout/linkButton";
import { getSiteImageFullPath } from "~/lib/siteMedia";

export interface IErrorMessageComponent {
	backHomeCaption: string;
	backHomeTooltip: string;
}

const ErrorMessageComponent: React.FC<PropsWithChildren<IErrorMessageComponent>> = ({
	children,
	backHomeCaption,
	backHomeTooltip,
}) => {
	return (
		<div className={`grow shrink-0 flex items-stretch`} id="page_error">
			<div className="w-full h-full min-h-[400px] xl:min-h-[600px] max-w-screen-xl mx-auto">
				<div className="relative flex-row items-start h-full mx-6 lg:flex md:mx-8">
					<div className="m-1 my-12 xl:my-24 2xs:mx-8 basis-11/12 lg:basis-7/12 grow">
						<div id="heading_ope" className="flex flex-col items-start gap-2">
							<Heading1 id="error_component_heading">
								<div className="pb-4 text-center lg:text-left">{children}</div>
							</Heading1>
							<div className="flex flex-col items-center justify-center w-full pt-4 lg:w-fit">
								<div className="w-full max-w-md pt-4 lg:w-fit">
									<LinkButton
										className="block w-full text-center lg:inline-block lg:w-fit"
										href="/"
										tooltip={backHomeTooltip}
									>
										{backHomeCaption}
									</LinkButton>
								</div>
							</div>
						</div>
					</div>
					<div className="m-8 lg:basis-5/12 lg:grow">
						<div className="lg:absolute right-0 top-18 xl:top-36 left-1/2 xl:left-[40%] lg:-z-10">
							<div className="aspect-[31/18]">
								<div className="relative w-full h-full">
									<Image
										alt=""
										src={getSiteImageFullPath("error/cow.png")}
										priority
										fill
										className="object-contain"
										sizes="(min-width: 1280px) 730px, (min-width: 1024px) 50vw, 1px"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorMessageComponent;
