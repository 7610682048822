import React, { PropsWithChildren } from "react";

interface IHeaderProps {
	id: string;
	block?: boolean;
}

export const Heading1: React.FC<PropsWithChildren<IHeaderProps>> = ({ children, id, block }) => {
	return (
		<h1
			id={id}
			className={`text-3xl xs:text-4xl tracking-wide md:text-6xl font-fancy ${
				block ? "block" : ""
			}`}
		>
			{children}
		</h1>
	);
};
export const Heading2: React.FC<PropsWithChildren<IHeaderProps>> = ({ children, id, block }) => {
	return (
		<h2
			id={id}
			className={`text-3xl xs:text-4xl tracking-wide md:text-5xl font-fancy ${
				block ? "block" : ""
			}`}
		>
			{children}
		</h2>
	);
};
export const Heading3: React.FC<PropsWithChildren<IHeaderProps>> = ({ children, id, block }) => {
	return (
		<h3
			id={id}
			className={`text-2xl xs:text-3xl md:text-4xl tracking-wide font-fancy ${
				block ? "block" : ""
			}`}
		>
			{children}
		</h3>
	);
};
export const Heading3Alt: React.FC<PropsWithChildren<IHeaderProps>> = ({ children, id, block }) => {
	return (
		<h3 id={id} className={`text-3xl md:text-4xl tracking-wide font-fancy ${block ? "block" : ""}`}>
			{children}
		</h3>
	);
};

export const Subtitle1: React.FC<PropsWithChildren<IHeaderProps>> = ({ children, id, block }) => {
	return (
		<h4 id={id} className={`text-3xl ${block ? "block" : ""}`}>
			{children}
		</h4>
	);
};

export const Subtitle2: React.FC<PropsWithChildren<IHeaderProps>> = ({ children, id, block }) => {
	return (
		<h4 id={id} className={`text-lg font-semibold uppercase ${block ? "block" : ""}`}>
			{children}
		</h4>
	);
};
export const Subtitle3: React.FC<PropsWithChildren<IHeaderProps>> = ({ children, id, block }) => {
	return (
		<h4
			id={id}
			className={`xl:text-base md:text-sm 2xs:text-xs leading-3 2xs:leading-none text-[10px] font-semibold uppercase ${
				block ? "block" : ""
			}`}
		>
			{children}
		</h4>
	);
};
