export function getSiteImageFullPath(relativePath: string): string {
	return getSiteMediaFullPath(relativePath, "images");
}
export function getSiteVideoFullPath(relativePath: string): string {
	return getSiteMediaFullPath(relativePath, "videos");
}
export function getSiteMediaFullPath(relativePath: string, kind: "videos" | "images"): string {
	const pathMinusLeadingSlashes = relativePath.replace(/^\/+/g, "");
	return `${
		process.env.NEXT_PUBLIC_BASE_PRELOADED_MEDIA_URL_PATH || ""
	}/site/${kind}/${pathMinusLeadingSlashes}`;
}
