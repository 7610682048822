import * as React from "react";
import messages from "~/resources/messages/en-US/app/footer.json";
import FooterSiteSelfLinks from "./footerSiteSelfLinks";

interface IFooterProps {}

const Footer: React.FC<IFooterProps> = () => {
	return (
		<div className="w-full text-white bg-[#003746]" id="footer">
			<div className="flex flex-col max-w-screen-lg gap-4 py-10 mx-auto">
				<div className="flex flex-row justify-center gap-4 mx-8">
					<a
						href="https://www.linkedin.com/company/epic1979"
						id="footer_linkedin"
						title={messages.footer.linkedInTooltip.value}
						className="relative w-8 h-8 text-white hover:text-epic-light-red focus-within:text-epic-light-red"
					>
						<LinkedIn width={32} height={32} />
					</a>
					<a
						href="https://www.instagram.com/lifeatepic/"
						id="footer_instagram"
						title={messages.footer.instagramTooltip.value}
						className="relative w-8 h-8 text-white hover:text-epic-light-red focus-within:text-epic-light-red"
					>
						<Instagram width={32} height={32} />
					</a>
					<a
						href="https://www.tiktok.com/@lifeatepic"
						id="footer_tiktok"
						title={messages.footer.tikTokTooltip.value}
						className="relative w-8 h-8 text-white hover:text-epic-light-red focus-within:text-epic-light-red"
					>
						<TikTok width={32} height={32} />
					</a>
					<a
						href="https://www.facebook.com/LifeAtEpic"
						id="footer_facebook"
						title={messages.footer.facebookTooltip.value}
						className="relative w-8 h-8 -ml-2 text-white hover:text-epic-light-red focus-within:text-epic-light-red"
					>
						<Facebook width={32} height={32} />
					</a>
				</div>
				<FooterSiteSelfLinks />
				<div className="grid content-center grid-cols-1 mx-6 text-sm sm:flex sm:flex-row sm:justify-center">
					<div className="text-center">{messages.footer.streetAddress.value}</div>
					<div className="text-center sm:pl-4 sm:ml-4 sm:border-l-2">
						{messages.footer.cityState.value}
					</div>
					<div className="text-center sm:pl-4 sm:ml-4 sm:border-l-2 hover:underline">
						<a href="mailto:careers@epic.com">{messages.footer.contactEmail.value}</a>
					</div>
					<div className="text-center sm:pl-4 sm:ml-4 sm:border-l-2 hover:underline">
						<a href="https://www.epic.com/about/privacypolicies">
							{messages.footer.privacyPolicies.value}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Footer;

const Facebook = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
		<title>{messages.footer.facebookAltText.value}</title>
		<path
			d="M261.62 108.9s-6.8-1.37-22.1-1.37c-25.5 0-20.4 54.87-20.4 54.87h40.8s-6.37 42.01-6.37 42.92-31.87 0-31.87 0v117.03h-48.02V205.32h-35.27V162.4h35.7v-40.21c0-75.12 87.55-54.05 87.55-54.05v40.77Z"
			strokeWidth={0}
			fill="currentColor"
		/>
	</svg>
);

const Instagram = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
		<title>{messages.footer.instagramAltText.value}</title>
		<path
			d="M267.98 87.03h-138.8c-23.21 0-42.03 18.82-42.03 42.03v136.29c0 23.21 18.82 42.03 42.03 42.03h138.8c23.21 0 42.03-18.82 42.03-42.03V129.06c0-23.21-18.82-42.03-42.03-42.03ZM200 256.99c-31.71 0-57.42-25.71-57.42-57.42s25.71-57.42 57.42-57.42 57.42 25.71 57.42 57.42-25.71 57.42-57.42 57.42Zm75.66-118.78c-8.55 0-15.48-6.93-15.48-15.48s6.93-15.48 15.48-15.48 15.48 6.93 15.48 15.48-6.93 15.48-15.48 15.48Z"
			strokeWidth={0}
			fill="currentColor"
		/>
	</svg>
);

const LinkedIn = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 400 400" {...props}>
		<title>{messages.footer.linkedInAltText.value}</title>
		<path d="M100.46 140.33H147v144.53h-46.54z" fill="currentColor" strokeWidth={0} />
		<circle
			cx={125.29}
			cy={98.05}
			r={25.28}
			fill="currentColor"
			strokeWidth={0}
			transform="rotate(-71.57 125.286 98.046)"
		/>
		<path
			d="M317.48 284.86h-51.46v-83.67c0-23.29-31.14-30.67-44.75-10.07v93.75h-46.53V140.34h46.53v16.95c8.56-8.45 21.58-16.95 39.38-16.95 35.35 0 56.83 23.27 56.83 63.09v81.44Z"
			fill="currentColor"
			strokeWidth={0}
		/>
	</svg>
);

const TikTok = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
		<title>{messages.footer.tikTokAltText.value}</title>
		<path
			d="M108.82 225.01c1.07-5.05 1.78-10.22 3.27-15.15 8.09-26.79 33.18-46.85 61.1-48.82 5.2-.37 10.47.25 15.71.42 1.21.04 1.8.53 1.78 1.89-.17 13-.29 25.99-.42 38.99 0 .2-.1.41-.16.61-2.68-.42-5.32-.98-7.99-1.23-19.83-1.86-35.28 15.14-34.38 33.12.61 12.11 9.78 26.05 24.15 29.4 13.62 3.17 27.27-2.35 35.1-14.79 3.6-5.71 3.76-11.87 3.8-18.12.15-22.42.2-44.84.25-67.26.06-23.74.08-47.47.11-71.21V77.11h36.44c.56 3.56.95 7.25 1.75 10.85 5.13 23.04 19.7 36.76 42.26 42.44 3.46.87 7.01 1.42 10.53 2 1.11.18 1.66.43 1.66 1.71-.05 11.69-.03 23.37-.04 35.06 0 .28-.1.55-.2 1.05-19.64-.59-37.55-6.29-54.19-17.81v11.44c0 18.84.02 37.69-.01 56.53 0 5.18-.03 10.37-.31 15.54-1.75 32.21-27.8 60.33-59.7 64.57-1.57.21-3.13.51-4.7.77H172.8c-.56-.14-1.11-.34-1.67-.4-29.29-3.13-54.17-25.33-60.54-54.16-.87-3.96-1.19-8.04-1.76-12.06V225Z"
			strokeWidth={0}
			fill="currentColor"
		/>
	</svg>
);
